<template>
  <div class="football-item-wrap">
    <div class="league" :style="{ color: info.color }">
      <template v-if="scoreLanguage === 'zh_TW'">
        {{ info.league_name_zht ? info.league_name_zht : info.league_name_zh }}
      </template>
      <template v-else-if="scoreLanguage === 'en_US'">
        {{ info.league_name_sb ? info.league_name_sb : info.league_name_zh }}
      </template>
      <template v-else>
        {{ info.league_name_zh }}
      </template>
    </div>
    <div class="time">{{ info.match_time }}</div>
    <div class="statues" :class="{ wait: info.state === 0 }">
      <template v-if="info.state >= 1 && info.state <= 5">
        {{ info.on_time }}'
      </template>
      <template v-if="info.state == 0"> 未 </template>
      <template v-if="info.state < 0">
        {{ state[info.state] ? state[info.state] : "完" }}
      </template>
    </div>
    <div class="home">
      <span v-if="info.home_yellow && scoreCardShow" class="yellow">{{
        info.home_yellow
      }}</span>
      <span v-if="info.home_red && scoreCardShow" class="red">{{
        info.home_red
      }}</span>
      <a :href="`/detail/1/${info.tournament_id}`" target="_blank">
        <span class="name">
          <template v-if="scoreLanguage === 'zh_TW'">
            {{ info.home_name_zht ? info.home_name_zht : info.home_name_zh }}
          </template>
          <template v-else-if="scoreLanguage === 'en_US'">
            {{ info.home_name_sb ? info.home_name_sb : info.home_name_zh }}
          </template>
          <template v-else>
            {{ info.home_name_zh }}
          </template>
        </span>
      </a>
    </div>
    <div class="score" :class="{ wait: info.state === 0 }">
      <template v-if="info.state === 0"> - - </template>
      <template v-else> {{ info.home_score }}-{{ info.away_score }} </template>
    </div>
    <div class="away">
      <a :href="`/detail/1/${info.tournament_id}`" target="_blank">
        <span class="name">
          <template v-if="scoreLanguage === 'zh_TW'">
            {{ info.away_name_zht ? info.away_name_zht : info.away_name_zh }}
          </template>
          <template v-else-if="scoreLanguage === 'en_US'">
            {{ info.away_name_sb ? info.away_name_sb : info.away_name_zh }}
          </template>
          <template v-else>
            {{ info.away_name_zh }}
          </template>
        </span>
      </a>
      <span v-if="info.away_yellow && scoreCardShow" class="yellow">{{
        info.away_yellow
      }}</span>
      <span v-if="info.away_red && scoreCardShow" class="red">{{
        info.away_red
      }}</span>
    </div>
    <div class="half" :class="{ wait: info.state === 0 }">
      <template v-if="info.state === 0"> - - </template>
      <template v-else>
        {{ info.home_score_up }}-{{ info.away_score_up }}
      </template>
    </div>
    <div class="corner" :class="{ wait: info.state === 0 }">
      <img src="@/assets/img/score/corner.png" alt="" />
      <template v-if="info.state === 0">
        <span>- -</span>
      </template>
      <template v-else>
        <span>{{ info.home_corner }}-{{ info.away_corner }}</span>
      </template>
    </div>
    <div class="video">
      <a :href="`/detail/1/${info.tournament_id}`" target="_blank">
        <img v-if="info.is_zb" src="@/assets/img/score/video.png" alt="" />
        <img src="@/assets/img/score/live.png" alt="" v-else />
      </a>
    </div>
    <div class="odd">
      <div>
        <span :class="[bClass]">{{ figure.b }}</span>
        <span>{{ figure.a }}</span>
        <span :class="[cClass]">{{ figure.c }}</span>
      </div>
      <div>
        <span :class="[eClass]">{{ figure.e }}</span>
        <span>{{ figure.d }}</span>
        <span :class="[fClass]">{{ figure.f }}</span>
      </div>
    </div>
    <a :href="`/detail/1/${info.tournament_id}/eu`" target="_blank">
      <div class="data">析</div>
    </a>

    <div class="live">
      <img
        @click="getAnchor"
        v-if="info.is_zb"
        src="@/assets/img/score/voice.png"
        alt=""
      />
    </div>
    <div class="play">
      <template v-if="routerName != 'collect'">
        <img
          v-if="isTop"
          @click="top"
          src="@/assets/img/score/down.png"
          alt=""
        />
        <img v-else @click="top" src="@/assets/img/score/top.png" alt="" />
      </template>
      <img
        v-if="isCollect"
        @click="collect"
        src="@/assets/img/score/collect-active.png"
        alt=""
      />
      <img
        v-else
        @click="collect"
        src="@/assets/img/score/collect.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "football_item",
  props: {
    info: Object,
    collectIds: Array,
  },
  data() {
    return {
      localTopIds: [],
      state: {
        "-1": "完场",
        "-10": "取消",
        "-11": "待定",
        "-12": "腰斩",
        "-13": "中断",
        "-14": "推迟",
      },
      bClass: "",
      cClass: "",
      eClass: "",
      fClass: "",
    };
  },
  computed: {
    ...mapState({
      scoreLanguage: "scoreLanguage",
      scoreCardShow: "scoreCardShow",
    }),
    routerName() {
      return this.$route.name;
    },
    isTop() {
      return this.localTopIds.indexOf(this.info.tournament_id) > -1;
    },
    isCollect() {
      return this.collectIds.indexOf(this.info.tournament_id) > -1;
    },
    figure() {
      const figureInfo = {
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
        f: "",
      };
      // if(["collect"].includes(this.$route.name)){
      //   figureInfo.a = this.info.a || ""
      //   figureInfo.b = this.info.b || ""
      //   figureInfo.c = this.info.c || ""
      //   figureInfo.d = this.info.d || ""
      //   figureInfo.e = this.info.e || ""
      //   figureInfo.f = this.info.f || ""
      //   return figureInfo
      // }

      if (
        this.info &&
        ((this.info.c && typeof this.info.c === "string") ||
          (this.info.b && typeof this.info.b === "string") ||
          (this.info.a && typeof this.info.a === "string"))
      ) {
        let figure = {
          1: ["", "", ""],
          2: ["", "", ""],
          3: ["", "", ""],
        };
        if (this.info.a && typeof this.info.a === "string") {
          figure = Object.assign({}, figure, JSON.parse(this.info.a));
        }
        if (this.info.b && typeof this.info.b === "string") {
          figure = Object.assign({}, figure, JSON.parse(this.info.b));
        }
        if (this.info.c && typeof this.info.c === "string") {
          figure = Object.assign({}, figure, JSON.parse(this.info.c));
        }
        if (
          (this.info.c && typeof this.info.c === "string") ||
          (this.info.b && typeof this.info.b === "string") ||
          (this.info.a && typeof this.info.a === "string")
        ) {
          if (figure["1"] && figure["1"].length > 0) {
            figureInfo.a = figure["1"]["0"];
            figureInfo.b = figure["1"]["1"];
            figureInfo.c = figure["1"]["2"];
          }
          if (figure["3"] && figure["3"].length > 0) {
            figureInfo.d = figure["3"]["0"];
            figureInfo.e = figure["3"]["1"];
            figureInfo.f = figure["3"]["2"];
          }
        }
      }
      return figureInfo;
    },
  },
  watch: {
    "figure.b": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.bClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.bClass = "down";
        }
        const timer = setTimeout(() => {
          this.bClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
    "figure.c": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.cClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.cClass = "down";
        }
        const timer = setTimeout(() => {
          this.cClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
    "figure.e": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.eClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.eClass = "down";
        }
        const timer = setTimeout(() => {
          this.eClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
    "figure.f": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.fClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.fClass = "down";
        }
        const timer = setTimeout(() => {
          this.fClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
  },
  methods: {
    top() {
      this.$emit("toTop", this.info);
      if (!this.localTopIds.includes(this.info.tournament_id)) {
        this.localTopIds.push(this.info.tournament_id);
      } else {
        const idx = this.localTopIds.indexOf(this.info.tournament_id);
        this.localTopIds.splice(idx, 1);
      }
    },
    collect() {
      this.$emit("collect", this.info);
    },
    getAnchor() {
      this.$emit("getAnchor", this.info);
    },
  },
  mounted() {
    this.localTopIds =
      JSON.parse(localStorage.getItem("football_top_ids")) || [];
  },
};
</script>

<style lang="less" scoped>
.football-item-wrap {
  display: flex;
  height: 54px;
  line-height: 54px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  color: #000;
  font-size: 14px;
  justify-content: space-between;
  text-align: center;

  .league {
    width: 92px;
  }

  .time {
    width: 80px;
  }

  .statues {
    width: 80px;
    color: #df1111;

    &.wait {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .home {
    justify-content: end;
  }

  .home,
  .away {
    width: 148px;
    white-space: nowrap;
    display: flex;
    color: rgba(0, 0, 0, 0.9);
    align-items: center;
    gap: 4px;

    .name {
      max-width: 116px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      color: #000;
    }

    .rank {
      font-size: 12px;
    }

    .yellow {
      text-align: center;
      width: 12px;
      height: 16px;
      line-height: 16px;
      background: url("../../assets/img/score/yellow-card.png") no-repeat;
      color: #fff;
      font-size: 12px;
    }

    .red {
      text-align: center;
      width: 12px;
      height: 16px;
      line-height: 16px;
      background: url("../../assets/img/score/red-card.png") no-repeat;
      color: #fff;
      font-size: 12px;
    }
  }

  .score {
    width: 52px;
    color: #df1111;

    &.wait {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .half {
    width: 50px;

    &.wait {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .corner {
    width: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      display: block;
      width: 20px;
      height: 20px;
    }

    &.wait {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .video {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .odd {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;

    .up {
      color: #df1111;
    }

    .down {
      color: #24ae6e;
    }

    > div {
      display: flex;
      height: 20px;
      align-items: center;
      justify-content: center;
      gap: 10px;

      span {
        white-space: nowrap;
        width: 65px;
        line-height: 20px;
      }
    }
  }

  .data {
    width: 50px;
    cursor: pointer;
    color: #000;
  }

  .live {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .play {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #f8faff;
  }
}
</style>
